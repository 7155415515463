<template>
  <div class="box-page" style="padding-right: 15px">
    <div class="filter">
      <a-form layout="horizontal" ref="formRef" :model="formState">
        <a-row :gutter="20">
          <a-col span="5">
            <a-form-item label="风险等级" name="level">
              <a-select v-model:value="formState.level"
                        placeholder="请选择风险等级" allow-clear>
                <a-select-option :value="level.value"
                                 :label="level.label"
                                 v-for="level in levels"
                                 :key="level.label">{{ level.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col span="5">
            <a-form-item label="关键词组" name="keywordTypeId">
              <a-select v-model:value="formState.keywordTypeId"
                        placeholder="请选择关键词组"
                        show-search allow-clear
                        :filterOption="(input, option)=> { return option.label.indexOf(input) >= 0;}">
                <a-select-option
                    :value="item.value"
                    :label="item.label"
                    v-for="item in keywordList"
                    :key="item.value">{{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col span="5">
            <a-form-item label="关键词" name="keyword">
              <a-input v-model:value="formState.keyword" placeholder="请输入关键词"/>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item>
              <a-button type="primary" @click="handelSearch">
                查询
              </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handelReset">
                重置
              </a-button>
              <a-button type="primary" :style="{ marginLeft: '8px' }" @click="visible = true">
                添加质检规则
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-list" style="padding-top: 5px">
      <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          size="mini"
          :row-style="{height:'50px'}"
          :cell-style="{padding:'0px'}"
          tooltip-effect="dark"
          style="width: 100%; overflow: auto"
          :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
      >
        <el-table-column label="风险等级" width="100">
          <template #default="{ row }">
            <span>{{ levelDesc(row) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="触发方式" width="100">
          <template #default="{ row }">
            <span>{{ ruleTypeDesc(row) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="关键词组" width="100">
          <template #default="{row}">
            <el-tooltip placement="top">
              <template #content>
                <p class="ellipsis" style="max-width: 300px">
                  {{ row.name }}
                </p>
              </template>
              <p class="ellipsis">
                {{ row.name }}
              </p>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="关键词" width="650">
          <template #default="{ row }">
            <el-tooltip>
              <template #content>
                <p class="ellipsis-line3" style="max-width: 650px">
                  {{ row.keywords ? row.keywords : '--' }}
                </p>
              </template>
              <p class="ellipsis-line3">
                {{ row.keywords ? row.keywords : '--' }}
              </p>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="规则" width="200">
          <template #default="{ row }">
            <p class="ellipsis-line3">
              {{ ruleDesc(row) }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="100">
          <template #default="{ row }">
            <span>{{ statusDesc(row) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建/更新" width="250px" prop="createTime_updateTime"></el-table-column>
        <el-table-column label="操作" width="150">
          <template #default="{row}">
            <a-button size="small" style="margin-right: 8px" type="primary" @click="handelEdit(row)">编辑</a-button>
            <a-popconfirm
                title="确定删除此条数据?"
                ok-text="确定"
                cancel-text="取消"
                @confirm="handelConfirm(row)"
            >
              <a-button size="small" type="primary" danger>删除</a-button>
            </a-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pager" style="padding-top: 8px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageInfo.currentPage"
          :page-size="pageInfo.pageSize"
          background :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.total">
      </el-pagination>
    </div>

    <a-modal width="690px" v-model:visible="visible" title="配置质检规则" @cancel="handleCancel">
      <div class="add-from-s">
        <a-form layout="horizontal" label-align="right" :model="formCreateUpdate" :rules="rules" ref="formRef">
          <a-form-item has-feedback label="风险等级" name="level">
            <a-radio-group v-model:value="formCreateUpdate.level">
              <a-radio :value="level.value" v-for="level in levels" :key="level.label">{{ level.label }}</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item has-feedback label="触发方式" name="ruleType">
            <a-radio-group v-model:value="formCreateUpdate.ruleType">
              <a-radio :value="ruleType.value" v-for="ruleType in ruleTypes" :key="ruleType.label">
                {{ ruleType.label }}
              </a-radio>
            </a-radio-group>
          </a-form-item>


          <a-form-item v-if="formCreateUpdate.ruleType==0" label="关键词组" name="keywordTypeId">
            <a-select
                v-model:value="formCreateUpdate.keywordTypeId"
                style="width: 100%"
                show-search allow-clear
                placeholder="请输入关键词组名称">
              <a-select-option
                  :value="item.value"
                  :label="item.label"
                  v-for="item in keywordList"
                  :key="item.value">{{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item v-if="formCreateUpdate.ruleType==0" label="配置规则">
            <span>至少触发关键词组中的<a-input :style="{width: '80px',margin: '0px 5px', padding: '1px 5px'}"
                                               type="number" min="1" v-model:value="formCreateUpdate.keywordHitNum"
                                               placeholder=""/>个关键词视为命中该关键词组</span>
          </a-form-item>

          <a-form-item v-if="formCreateUpdate.ruleType==1" label="配置规则">
            <span>主叫
              <a-input :style="{width: '80px',margin: '0px 5px', padding: '1px 5px'}"
                       type="number" min="1" v-model:value="formCreateUpdate.upgradeDay"
                       placeholder=""/>天
              <a-input :style="{width: '80px',margin: '0px 5px', padding: '1px 5px'}"
                       type="number" min="1" v-model:value="formCreateUpdate.upgradeNum"
                       placeholder=""/>次 触发{{ lowerLevelLabel }}升级为{{ currentLevelLabel }}</span>
          </a-form-item>
          <a-form-item v-if="formCreateUpdate.ruleType==1" label="主叫">
            <span>为同一渠道下的所有主叫</span>
          </a-form-item>


          <a-form-item has-feedback label="状态" name="status">
            <a-radio-group v-model:value="formCreateUpdate.status">
              <a-radio :value="status.value" v-for="status in statuses" :key="status.label">{{ status.label }}</a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item has-feedback label="处罚方式" name="punish">
            <a-select
                v-model:value="formCreateUpdate.punish"
                style="width: 100%"
                placeholder="请选择处罚方式">
              <a-select-option
                  :value="item.value"
                  :label="item.label"
                  v-for="item in punishes"
                  :key="item.value">{{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="备注">
            <span>企业禁用后，该企业下所有主叫均不可以使用系统拨打<br>该渠道下所有企业的主叫均不可以使用系统拨打</span>
          </a-form-item>

        </a-form>
      </div>
      <template #footer>
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" :loading="btnLoading" @click="handelSave">保存</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import {reactive, toRaw, ref, computed} from 'vue';
import {PageModule} from '../common/pagination'
import _lineService from "@/api/open/LineQualityInspections";
import dictionary from '@/api/system/dictionary'
import {lastMonthConsume} from '@/api/dashboard/dashboard';
import amount from '@/api/open/amount/amount'
import moment from "moment/moment";


export default {
  name: 'RuleTab',
  props: {
    voiceOption: {
      type: Array,
      default: () => []
    },
    isAdminUser: {
      type: String,
      default: () => ''
    },
  },

  data() {
    return {
      ba: "",
      keywordList: [],
      loading: false,
      tableData: [],
      formState: reactive(new FormModel()),
      formCreateUpdate: reactive(new CreateUpdateModule()),
      visible: false,
      rowId: '',
      pageInfo: new PageModule(),
      btnLoading: false,
      formRef: ref(),
      rules: {
        level: [
          {required: true, type: 'number', message: '请选择风险等级', trigger: 'blur'}
        ],
        ruleType: [
          {required: true, type: 'number', message: '请选择触发方式', trigger: 'blur'}
        ],
        status: [
          {required: true, type: 'number', message: '请选择状态', trigger: 'blur'}
        ],
        punish: [
          {required: true, type: 'number', message: '请选择处罚方式', trigger: 'blur'}
        ],
      },
      levels: [
        {label: '特大违规', value: 1},
        {label: '重大违规', value: 2},
        {label: '一般违规', value: 3},
        // {label: '轻微违规', value: 4},
        // {label: '无违规', value: 5},
      ],
      ruleTypes: [
        {label: '关键词触发', value: 0},
        {label: '违规升级触发', value: 1},
      ],
      keywordHitTypes: [
        {label: '未命中', value: 0},
        {label: '命中', value: 1},
      ],
      statuses: [
        {label: '正常', value: 1},
        {label: '禁用', value: 0},
      ],
      punishes: [
        {label: '暂不惩罚', value: 0},
        {label: '禁用当前拨打主叫', value: 1},//禁用主叫 禁用当前拨打主叫
        {label: '禁用当前拨打主叫所在企业', value: 2},//禁用终端 禁用当前拨打主叫所在企业
        {label: '禁用当前主叫企业所属渠道', value: 3},//禁用代理 禁用当前主叫企业所属渠道
        {label: '禁用代理被叫号码', value: 4},//禁用代理 禁用代理被叫号码
      ],
    };
  },
  computed: {
    levelDesc() {
      return item => {
        // console.log('levelDesc', this.levels, item)
        return this.levels.find(listItem => listItem.value == item.level)?.label;
      }
    },
    ruleTypeDesc() {
      return item => {
        return this.ruleTypes.find(listItem => listItem.value == item.ruleType)?.label;
      }
    },
    // keywordHitTypeDesc() {
    //   return item => {
    //     return this.keywordHitTypes.find(listItem => listItem.value == item.keywordHitType)?.label;
    //   }
    // },
    statusDesc() {
      return item => {
        return this.statuses.find(listItem => listItem.value == item.status)?.label;
      }
    },
    punishDesc() {
      return item => {
        return this.punishes.find(listItem => listItem.value == item.punish)?.label;
      }
    },
    ruleDesc() {
      return item => {
        let res = '';
        if (item.ruleType == 0) {
          res = `至少触发关键词组中的${item.keywordHitNum}个关键词视为命中该关键词组`;
        }
        if (item.ruleType == 1) {
          let currentLevel = this.levels.find(level => level.value == item.level);
          let currentLevelLabel = currentLevel.label || '';
          let lowerLevel = this.levels.find(level => level.value == (item.level + 1));
          let lowerLevelLabel = lowerLevel?.label || '轻微违规';
          res = `主叫${item.upgradeDay}天${item.upgradeNum}次 触发${lowerLevelLabel}升级为${currentLevelLabel}`;
        }
        return res;
      }
    },
    currentLevelLabel() {
      let find = this.levels.find(level => level.value == this.formCreateUpdate.level);
      return find?.label || '';
    },
    //更低一级违规名称
    lowerLevelLabel() {
      let find = this.levels.find(level => level.value == (this.formCreateUpdate.level + 1));
      return find?.label || '轻微违规';
    },
  },
  methods: {
    async getKeywordList() {
      let parmas = {
        page: 1,
        pageSize: 9999,
        uid: 0,//查询属于’系统‘的关键词
        type: 1,//只查找‘风险类型’
      }
      const res = await _lineService._ruleService.getList(parmas)
      this.keywordList = res.records.map(item => {
        return {label: item.name, value: item.id};
      });
    },
    async handelConfirm(row) {
      await _lineService._ruleService.deleteRule({id: row.id})
      this.$message.success('删除成功')
      this.getList()
    },
    handelSearch() {
      this.pageInfo.init()
      this.getList()
    },
    async getList() {
      const res = await _lineService._ruleService.getRuleList(this.getParams());
      this.tableData = res?.data?.records?.map(item => new TableModule(item)) || [];
      console.log('getList', this.tableData[0], this.tableData[1], this.tableData[2])
      this.pageInfo.setTotal(res.data?.total || 0);
    },
    getParams() {
      const obj = toRaw(this.formState)
      return {
        ...this.pageInfo.getParamsJson(),
        ...obj.getParamsJson()
      }
    },
    async handelSave() {
      this.btnLoading = true
      try {
        const obj = toRaw(this.formCreateUpdate)
        await this.$refs.formRef.validate()
        if (this.rowId) {
          await _lineService._ruleService.editRule(obj.getUpdateObj(this.rowId))
        } else {
          await _lineService._ruleService.addRule(obj.getCreateObj())
        }
        this.rowId = ''
        this.$message.success('保存成功')
        this.formCreateUpdate = new CreateUpdateModule()
        ref(this.$refs.formRef).value.resetFields();
        this.getList()
        this.$nextTick(() => {
          this.visible = false
        })
      } catch (err) {
        if (err) {
          this.$message.error(err.message)
        }
      }
      this.btnLoading = false
    },
    handleSizeChange(val) {
      this.pageInfo.setPageSize(val)
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageInfo.setCurrentPage(val)
      this.getList()
    },
    handleCancel() {
      this.rowId = ''
      this.formCreateUpdate = new CreateUpdateModule()
      ref(this.$refs.formRef).value.resetFields();
      this.$nextTick(() => {
        this.visible = false
      })
    },
    handelEdit(row) {

      this.rowId = row.id
      this.visible = true
      this.formCreateUpdate.setObjItem(row)
      //  let a=  JSON.parse(  JSON.stringify(row))
      // this.ba=a.industryId

    },
    handelReset() {
      this.$refs.formRef.resetFields()
      this.handelSearch()
    }
  },
  created() {
    this.getList()
    this.getKeywordList()
  }
};


class TableModule {
  constructor(item) {
    this.id = item?.id;
    // 违规等级，1-特大违规 2-重大违规 3-一般违规 4-轻微违规
    this.level = item?.level || 1;
    // 规则类型，0-关键词触发 1-违规升级触发
    this.ruleType = item?.ruleType || 0;

    // 关键词组ID，rule_type为0时生效
    this.keywordTypeId = (item?.keywordTypeId || '') + '';//关键词组列表接口返回的是int类型，为select组件匹配，转为string类型
    // 规则的命中方式，0-未命中触发 1-命中触发 rule_type为0时生效
    this.keywordHitType = item?.keywordHitType;
    // 命中/未命中关键词个数，rule_type为0时生效
    this.keywordHitNum = item?.keywordHitNum;

    // 多少天内，rule_type为1时生效
    this.upgradeDay = item?.upgradeDay;
    // 触发多少次，rule_type为1时生效
    this.upgradeNum = item?.upgradeNum;
    // 违规类型，rule_type为1时生效
    this.upgradeLevel = item?.upgradeLevel;

    // 状态，0-停用 1-启用
    this.status = item?.status;
    // 惩罚，0-无惩罚 1-禁主叫 2-禁终端 3-禁代理
    this.punish = item?.punish;

    this.id = item?.id;
    this.name = item?.name;
    this.keywords = item?.keywords;
    this.createTime = item?.createTime;
    this.updateTime = item?.updateTime;
    this.createTime_updateTime = [item?.createTime || '', item?.updateTime || ''].join(' ');
  }

  getLevelText(val) {
    const levels = [
      {label: '特大违规', value: 0},
      {label: '重大违规', value: 1},
      {label: '一般违规', value: 2},
    ]
    return levels[val] ? levels[val] : ''
  }

  getRuleTypeText(val) {
    const ruleTypes = [
      {label: '关键词触发', value: 0},
      {label: '违规升级触发', value: 1},
    ]
    return ruleTypes[val] ? ruleTypes[val] : ''
  }

  getKeywordHitTypeText(val) {
    const keywordHitTypes = [
      {label: '未命中', value: 0},
      {label: '命中', value: 1},
    ]
    return keywordHitTypes[val] ? keywordHitTypes[val] : ''
  }

  getStatusText(val) {
    const statuses = [
      {label: '正常', value: 1},
      {label: '禁用', value: 0},
    ]
    return statuses[val] ? statuses[val] : ''
  }

  getPunishText(val) {
    const punishes = [
      {label: '暂不惩罚', value: 0},
      {label: '禁用当前拨打主叫', value: 1},//禁用主叫 禁用当前拨打主叫
      {label: '禁用当前拨打主叫所在企业', value: 2},//禁用终端 禁用当前拨打主叫所在企业
      {label: '禁用当前主叫企业所属渠道', value: 3},//禁用代理 禁用当前主叫企业所属渠道
    ]
    return punishes[val] ? punishes[val] : ''
  }

}

class FormModel {
  constructor() {
    // 违规等级，1-特大违规 2-重大违规 3-一般违规 4-轻微违规
    this.level = ''
    //关键词组id
    this.keywordTypeId = ''
    // 关键词
    this.keyword = ''
  }

  getParamsJson() {
    return {
      level: this.level,
      keywordTypeId: this.keywordTypeId,
      keyword: this.keyword,
    }
  }
}

class CreateUpdateModule {
  constructor() {
    // 违规等级，1-特大违规 2-重大违规 3-一般违规 4-轻微违规
    this.level = null,
        // 规则类型，0-关键词触发 1-违规升级触发
        this.ruleType = 0,

        // 关键词组ID，rule_type为0时生效
        this.keywordTypeId = null,
        // 规则的命中方式，0-未命中触发 1-命中触发 rule_type为0时生效
        this.keywordHitType = 1,
        // 命中/未命中关键词个数，rule_type为0时生效
        this.keywordHitNum = 0,

        // 多少天内，rule_type为1时生效
        this.upgradeDay = null,
        // 触发多少次，rule_type为1时生效
        this.upgradeNum = null,
        // 违规类型，rule_type为1时生效
        this.upgradeLevel = null,

        // 状态，0-停用 1-启用
        this.status = 1,
        // 惩罚，0-无惩罚 1-禁主叫 2-禁终端 3-禁代理
        this.punish = 0

    // this.id = null
    // this.name = ''
    // this.keywords = null
    // this.createTime = ''
    // this.updateTime = ''

  }

  getCreateObj() {
    return {
      // 违规等级，1-特大违规 2-重大违规 3-一般违规 4-轻微违规
      level: this.level || 1,
      // 规则类型，0-关键词触发 1-违规升级触发

      ruleType: this.ruleType || 0,

      // 关键词组ID，rule_type为0时生效
      keywordTypeId: this.keywordTypeId,
      // 规则的命中方式，0-未命中触发 1-命中触发 rule_type为0时生效
      keywordHitType: this.keywordHitType,
      // 命中/未命中关键词个数，rule_type为0时生效
      keywordHitNum: this.keywordHitNum,

      // 多少天内，rule_type为1时生效
      upgradeDay: this.upgradeDay,
      // 触发多少次，rule_type为1时生效
      upgradeNum: this.upgradeNum,
      // 违规类型，rule_type为1时生效
      upgradeLevel: this.upgradeLevel,

      // 状态，0-停用 1-启用
      status: this.status,
      // 惩罚，0-无惩罚 1-禁主叫 2-禁终端 3-禁代理
      punish: this.punish,

      // name: this.name,
      // keywords: this.keywords,
      // createTime: this.createTime ? this.createTime : moment().format("yyyy-MM-dd hh:mm:ss"),
      // updateTime: moment().format("yyyy-MM-dd hh:mm:ss"),
    }
  }

  getUpdateObj(id) {
    return {
      id: id,
      // 违规等级，1-特大违规 2-重大违规 3-一般违规 4-轻微违规
      level: this.level || 1,
      // 规则类型，0-关键词触发 1-违规升级触发

      ruleType: this.ruleType || 0,

      // 关键词组ID，rule_type为0时生效
      keywordTypeId: this.keywordTypeId + '',
      // 规则的命中方式，0-未命中触发 1-命中触发 rule_type为0时生效
      keywordHitType: this.keywordHitType,
      // 命中/未命中关键词个数，rule_type为0时生效
      keywordHitNum: this.keywordHitNum,

      // 多少天内，rule_type为1时生效
      upgradeDay: this.upgradeDay,
      // 触发多少次，rule_type为1时生效
      upgradeNum: this.upgradeNum,
      // 违规类型，rule_type为1时生效
      upgradeLevel: this.upgradeLevel,

      // 状态，0-停用 1-启用
      status: this.status,
      // 惩罚，0-无惩罚 1-禁主叫 2-禁终端 3-禁代理
      punish: this.punish,

      // name: this.name,
      // keywords: this.keywords,
      // createTime: this.createTime,
      // updateTime: this.updateTime,
    }
  }

  setObjItem(item) {
    this.id = item?.id;
    // 违规等级，1-特大违规 2-重大违规 3-一般违规 4-轻微违规
    this.level = item?.level || 1;
    // 规则类型，0-关键词触发 1-违规升级触发
    this.ruleType = item?.ruleType || 0;

    // 关键词组ID，rule_type为0时生效
    this.keywordTypeId = (item?.keywordTypeId || '') + '';//关键词组列表接口返回的是int类型，为select组件匹配，转为string类型
    // 规则的命中方式，0-未命中触发 1-命中触发 rule_type为0时生效
    this.keywordHitType = item?.keywordHitType;
    // 命中/未命中关键词个数，rule_type为0时生效
    this.keywordHitNum = item?.keywordHitNum;

    // 多少天内，rule_type为1时生效
    this.upgradeDay = item?.upgradeDay;
    // 触发多少次，rule_type为1时生效
    this.upgradeNum = item?.upgradeNum;
    // 违规类型，rule_type为1时生效
    this.upgradeLevel = item?.upgradeLevel;

    // 状态，0-停用 1-启用
    this.status = item?.status;
    // 惩罚，0-无惩罚 1-禁主叫 2-禁终端 3-禁代理
    this.punish = item?.punish;

    this.name = item?.name;
    this.keywords = item?.keywords;
    this.createTime = item?.createTime;
    this.updateTime = item?.updateTime;
  }

}

</script>
<style lang="less" scoped>
.add-from-s /deep/ .ant-form-item-label > label {
  width: 100px;
  //text-align: end;
  //display:inline-block;
  //margin: 0px auto;
}

.ellipsis {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

.ellipsis-line3 {
  white-space: normal !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
  cursor: pointer;
}

.box-page /deep/ .el-table .cell {
  overflow: hidden;
  white-space: nowrap; //禁止换行
  text-overflow: ellipsis; //...
}
</style>